export default function company() {
  // ANIMAZIONI CARD FLIP
  let buttons= document.querySelectorAll(".slide-group__tab");
  buttons.forEach(function (button) {
    button.addEventListener("click", function () {
      buttons.forEach(function (btn) {
        btn.classList.remove("active");
      });
      this.classList.add("active");
      
      document.querySelectorAll(".company").forEach(function (company, index) {
        if (window.matchMedia("(max-width: 992px)").matches) { 
          index = 0;
        } 
        setTimeout(function () {
          if (button.id === "ambito") {
            company.classList.add("company--ambito");
          } else {
            company.classList.remove("company--ambito");
          }
        }, index * 70);
      });
    });
  });

  // SCROLL BUTTONS UNIT E AMBITO
  let buttonsWrap = document.querySelector(".slide-group__tab-wrap");
  let container = document.querySelector(".slide-group__companies");
  window.addEventListener("scroll", function () {
    let containerWidth = container.offsetWidth;
    let buttonsWrapWidth = buttonsWrap.offsetWidth;
    let posX = container.getBoundingClientRect().left;
    //condizione: se l'estremo sx del container supera la viewport 
    //ma il container stesso non ha superato completamente la viewport
    if (posX < 0 && posX > -(containerWidth)) {
      buttonsWrap.style.left = -posX.toString() + 'px';
      //condizione: se l'estremo sx del container è alla fine della sua larghezza 
      //togliendo la width del wrappatore
      if(posX < -(containerWidth - buttonsWrapWidth)){
        let calc = containerWidth - buttonsWrapWidth;
        buttonsWrap.style.left= calc.toString() + 'px';
      }
    }
    else {
      buttonsWrap.style.left = 0;
    }
  });
}