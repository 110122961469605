import $ from 'jquery';

export default function blogList() {
  
  function filterPost() {

    const validFilter = ["all", "news", "project-management", "tech", "user-experience", "eventi", "innovazione", "case-study", "elearning"];

    $('.blog-list .blog-list__filter .btn-filter').on('click', function(e) {
			e.preventDefault();
      
      const category = $(this).data("filter");
			$('.blog-list .blog-list__filter .btn-filter').removeClass("active");
			$(this).addClass('active');
      location.hash = category;
      
      if (category === 'all') {
        $('.blog-list .blog-list__post .box-post').removeClass('d-none');
      } else {
        $('.blog-list .blog-list__post .box-post').addClass('d-none');
        $(`.box-post[data-category='${category}']`).removeClass('d-none');
      }
		});

    if (window.location.hash) {
      const hash = window.location.hash.split('#')[1];

      if(validFilter.indexOf(hash) > -1) {

        $('.blog-list .blog-list__filter .btn-filter').removeClass("active");
        $(`.blog-list .blog-list__filter .btn-filter[data-filter='${hash}']`).addClass('active');
        
        if (hash === 'all') {
          $('.blog-list .blog-list__post .box-post').removeClass('d-none');
        } else {
          $('.blog-list .blog-list__post .box-post').addClass('d-none');
          $(`.box-post[data-category='${hash}']`).removeClass('d-none');
        }

      }
    }
  }

  $( document ).ready(function() {

    if($('.blog-list .blog-list__filter .btn-filter').length > 0) {
      filterPost();
    }

  });
}